import React from 'react';
import styled from 'styled-components';

import { ScrollStyles } from '@/components/styles';

import { StockFilterTextAreaProps } from './types';

function StockFilterTextArea({
  className,
  ...props
}: StockFilterTextAreaProps) {
  return <Area className={className} {...props} />;
}

export default StockFilterTextArea;

const Area = styled.textarea`
  display: block;
  width: 100%;
  padding: 11px 15px 10px;
  min-height: 120px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;
  resize: none;

  ${ScrollStyles}

  &::placeholder {
    color: ${(props) => props.theme.blue100};
  }
`;
