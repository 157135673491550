import React from 'react';
import styled from 'styled-components';

import { StockFilterInputProps } from './types';

function StockFilterInput({
  className,
  errorMessage,
  ...props
}: StockFilterInputProps) {
  return (
    <Component>
      <Input className={className} {...props} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default StockFilterInput;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 11px 15px 10px;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;

  &::placeholder {
    color: ${(props) => props.theme.blue100};
  }
`;

const Error = styled.div`
  position: absolute;
  color: rgba(244, 67, 54, 1);
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  bottom: -18px;
`;
