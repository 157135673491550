import { random } from 'lodash';

import { useModal } from '@tager/web-components';

import useSettingItem from '@/hooks/useSettingItem';
import { googleEvent } from '@/utils/events';
import CarSelectionHelperModal from '@/components/modals/CarSelectionHelperModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { CarSelectionHelperFormFields } from '@/components/modals/CarSelectionHelperModal/types';
import { submitCarHelper } from '@/services/leads/leads-service';

export function useCarSelectionHelper(isWithoutGoogleEvent?: boolean) {
  const modal = useModal();

  const images = useSettingItem('HELPER_CARD_IMAGES');
  const randomValue = random(0, images ? images.length - 1 : 0);

  const modalText = useSettingItem('HELPER_MODAL_TEXT');
  const modalImage = useSettingItem('HELPER_MODAL_IMAGE');
  const modalGoogleEvent = useSettingItem('HELPER_MODAL_GOOGLE_EVENTS')?.[
    randomValue
  ]?.googleEvent;

  const cardGoogleEvent = useSettingItem('HELPER_CARD_GOOGLE_EVENTS')?.[
    randomValue
  ]?.googleEvent;

  const image = images?.[randomValue];

  const dieText = useSettingItem('HELPER_DIE_TEXT');
  const dieImageOn = useSettingItem('HELPER_DIE_IMAGE_ON');
  const dieImageOff = useSettingItem('HELPER_DIE_IMAGE_OFF');

  async function handleSubmitCarSelectionHelperForm(
    values: CarSelectionHelperFormFields
  ): Promise<void> {
    try {
      await submitCarHelper(values);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  const openModal = () => {
    if (!isWithoutGoogleEvent && cardGoogleEvent) {
      googleEvent(cardGoogleEvent);
    }

    modal(
      CarSelectionHelperModal,
      {
        text: modalText ?? '',
        image: modalImage ?? { url: '' },
        onSubmit: (values) =>
          handleSubmitCarSelectionHelperForm(values).then(() => {
            if (!isWithoutGoogleEvent && modalGoogleEvent) {
              googleEvent(modalGoogleEvent);
            }
          }),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return {
    image,
    openModal,
    die: {
      text: dieText ?? '',
      imageOn: dieImageOn ?? { url: '' },
      imageOff: dieImageOff ?? { url: '' },
      onClick: openModal,
    },
  };
}
